<template>
  <div class="layout-activity">
    <BlockHeader :links="headerLinks" />

    <!-- ActivityHero -->
    <UtilWrapperLayout>
      <UtilGrid>
        <div v-if="extraData" class="layout-activity__hero-article">
          <div
            class="layout-activity__hero-article-back"
            @click="router.back()"
            @keypress="router.back()"
          >
            <IonIcon icon-name="chevron-left" />
            <p>
              {{ useStoryblokTranslation('general', 'back_to_overview') }}
            </p>
          </div>

          <div
            v-if="extraData?.tags?.length > 0"
            class="layout-activity__hero-article-tags"
          >
            <p
              v-if="extraData?.country"
              class="layout-activity__hero-article-tag"
            >
              {{ extraData.country.name }}
            </p>
            <p
              v-for="tag in extraData?.type"
              :key="tag.index"
              class="layout-activity__hero-article-tag"
            >
              {{ tag.name }}
            </p>
            <p
              v-for="tag in extraData?.tags"
              :key="tag.index"
              class="layout-activity__hero-article-tag"
            >
              {{ tag.name }}
            </p>
          </div>

          <AtomHeading
            v-if="extraData?.title"
            class="layout-activity__hero-article-title"
            :class="{ 'has-subtitle': extraData.sub_title }"
            :text="extraData.title"
            html-tag="h2"
            font-size="h2"
          />

          <AtomHeading
            v-if="extraData?.sub_title"
            class="layout-activity__hero-article-subtitle"
            :text="extraData.sub_title"
            html-tag="h2"
            font-size="h2"
          />

          <div class="layout-activity__hero-article-details">
            <p>
              {{ dayjs(firstPublishedAt).locale(locale).format('DD. MMMM YYYY') }}
            </p>
            <div class="layout-activity__hero-article-details-share">
              <div
                @click="share()"
                @keypress="share()"
              >
                <IonIcon
                  icon-name="share"
                />
              </div>
              <ClientOnly>
                <AtomSocialShare
                  v-for="network in networks"
                  :key="network.network"
                  :network="network.network"
                  :url="baseUrl + route.fullPath"
                  :title="extraData.title"
                  :description="extraData.sub_title"
                >
                  <IonIcon
                    :icon-name="network.network"
                  />
                </AtomSocialShare>
              </ClientOnly>
            </div>
          </div>
          <AtomLead
            v-if="extraData?.lead"
            class="layout-activity__hero-article-lead"
            :text="extraData.lead"
            color-schema="coral"
          />
        </div>
      </UtilGrid>
    </UtilWrapperLayout>

    <slot />
    <BlockServiceMenu
      v-if="serviceMenu?.show_service_navigation"
      :data="serviceMenu"
      :form-data="activityletterForm"
    />

    <BlockFooter v-bind="footer" />
  </div>
</template>

<script setup>
const props = defineProps({
    headerLinks: {
        type: Array,
        default: () => [],
    },

    footer: {
        type: Object,
        default: () => {},
    },

    serviceMenu: {
        type: Object,
        default: () => {},
    },

    activityletterForm: {
        type: Object,
        default: () => {},
    },

    extraData: {
        type: Object,
        default: () => {},
    },

    firstPublishedAt: {
        type: String,
        default: '',
    },

});

/*
    Variables
*/
const { dayjs } = useDayJs();
const { locale } = useI18n();

const route = useRoute();
const router = useRouter();
const runtimeConfig = useRuntimeConfig();
const baseUrl = computed(() => runtimeConfig.public.BASE_URL);

const networks = [
    { network: 'linkedin', icon: ['linkedin'] },
];

/*
    Functions
*/
const share = () => {
    // share url mobile
    if (navigator.share) {
        navigator.share({
            title: props.extraData.title,
            text: props.extraData.sub_title,
            url: baseUrl + route.fullPath,
        })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
    } else {
        // share url desktop
        window.location.href = `mailto:?subject=${props.extraData.title}&body=${props.extraData.sub_title}%0D%0A%0D%0A${baseUrl.value + route.fullPath}`;
    }
};

</script>
<style lang="scss">
.layout-activity {
    position: relative;
    background-color: var(--c-background);
}

.layout-activity__hero-article {
    @include default-content-columns;

    margin-top: 78px;
    margin-bottom: 33px;

    @include mobile {
        margin-bottom: 40px;
    }
}

.layout-activity__hero-article-details-share {
    display: flex;
    column-gap: 29px;

    path {
        stroke: var(--c-coral-4);
    }

    .ion-icon {
        cursor: pointer;
    }
}

.layout-activity__hero-article-back {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-bottom: 33px;
    color: var(--c-coral-4);
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    .ion-icon {
        transition: all 0.3s ease;

        path {
            stroke: var(--c-coral-4);
        }
    }

    p {
        padding-top: 1px;
    }

    &:hover {
        .ion-icon {
            transform: translate(-5px, 0)
        }
    }
}

.layout-activity__hero-article-tags {
    display: flex;
    margin-bottom: 10px;
    column-gap: 10px;

    @include mobile {
        margin-bottom: 20px;
    }
}

.layout-activity__hero-article-tag {
    padding: 6px 12px;
    border-radius: 4px;
    background: var(--c-coral-11);
    color: var(--c-coral-6);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag)
}

.layout-activity__hero-article-details {
    @include fluid('margin-bottom', 20px, 60px);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: var(--c-coral-1);
    column-gap: 40px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    row-gap: 5px;
}

.layout-activity__hero-article-title {
    margin-bottom: 10px;

    &.atom-heading {
        color: var(--c-coral-1);
    }

    &.has-subtitle {
        margin-bottom: 0;
    }
}

.layout-activity__hero-article-subtitle {
    margin-bottom: 10px;

    &.atom-heading {
        color: var(--c-coral-6);
    }
}
</style>
